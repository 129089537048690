<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-card-account-details"
            :title="baseMaterialCardTitle"
        >
          <v-row>
            <v-col xs="9" sm="9" md="10"></v-col>
            <v-col xs="3" sm="3" md="2" class="text-right">
              <v-btn small right color="info" class="mr-0" @click="$router.push({name: 'Users' })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="!user">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-card flat class="mb-1">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            label="First Name"
                            v-model="user.firstName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="Last Name"
                            v-model="user.lastName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="Username"
                            v-model="user.username"
                            prepend-icon="mdi-account-details"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="Email"
                            type="email"
                            v-model="user.email"
                            prepend-icon="mdi-mail"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-btn
                            small
                            color="secondary"
                            @click="changePassword"
                        >
                          <v-icon small class="mr-1">mdi-lock</v-icon>
                          Change password
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12" class="text-right">
              <v-btn
                  v-if="canAccess({module: 'saas', route: '/organization/users/:id', method: 'patch'})"
                  color="success" class="text-right" @click="updateUser">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn color="error" class="ml-2 text-right" @click="reset">
                <v-icon class="mr-1">mdi-undo</v-icon>
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import usersMixins  from "./mixins";
import globalMixins from "../../../../mixins/globalMixins";

export default {
  name: 'Account',

  mixins: [ globalMixins, usersMixins ],

  data: () => ( {
    user: null
  } ),

  props: {
    id: {
      type: String
    }
  },

  async created() {
    this.initialize();
  },

  computed: {

    baseMaterialCardTitle() {
      let label = 'Updating User';
      if ( this.user ) {
        return `${ label } ${ this.user.firstName ? `'${ this.user.firstName } ${ this.user.lastName }'` : '' }`;
      }
      else {
        return `${ label } ...`;
      }
    }
  },

  methods: {
    reset() {
      if ( confirm( "Are you sure you want to reset the page? (All your changes will be lost)" ) ) {
        this.initialize();
      }
    },

    async initialize() {
      this.user = null;
      const user = await this.getSendData( {
                                             'url': `/saas/organization/users/${ this.id }`,
                                             'method': 'get'
                                           } );

      this.user = user.item;
    },

    changePassword() {
      this.changeUserPassword( this.user );
    },

    updateUser() {
      const self = this;
      //validate the payload before invoking the API
      if ( !this.validateUserRecord( this.user ) ) {
        return false;
      }

      let newUser = { ...this.user };
      let apiOptions = {
        url: `/saas/organization/users/${ this.id }`,
        method: "patch",
        params: newUser
      };

      self.getSendData( apiOptions ).then( (  ) => {
        self.pushMessage( {
                            type: 'success',
                            title: `User updated`,
                            text: `User Information has been updated!.`
                          } );
        self.scrollToTop();
      } );
    },

  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
