/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import globalMixins from "@/mixins/globalMixins";

export default {
	name: 'usersMixins',
	
	mixins: [ globalMixins ],
	
	methods: {
		
		changeUserPassword: function ( user ) {
			const _self = this;
			if ( confirm( `Are you sure you want to generate and email a new reset password link to user: ${ user.username }?` ) ) {
				doResetPassword();
			}
			
			function doResetPassword() {
				const apiOptions = {
					'url': '/user/password/forgot',
					'method': 'post',
					'headers': {
						'env': 'portal'
					},
					"params": {
						"username": user.username,
						"auto_register_flow": "corsair_saas"
					}
				};
				_self.getSendData( apiOptions ).then( () => {
					_self.pushMessage( {
						                   type: 'success',
						                   title: `Reset User Password`,
						                   text: `A new password was generated and sent to the email of user ${ user.username }.`
					                   } );
				} );
			}
		},
		
		validateUserRecord: function ( user ) {
			let errors = [];
			if ( !user.firstName || user.firstName.trim() === '' ) {
				errors.push( "The field First Name is required!" );
			}
			
			if ( !user.lastName || user.lastName.trim() === '' ) {
				errors.push( "The field Last Name is required!" );
			}
			
			if ( !user.email || user.email.trim() === '' ) {
				errors.push( "The field Email is required!" );
			}
			
			if ( !this.validEmail( user.email ) ) {
				errors.push( "The provided email address is invalid!" );
			}
			
			if ( !user.username || user.username.trim() === '' ) {
				errors.push( "The field Username is required!" );
			}
			
			if ( errors.length > 0 ) {
				errors.forEach( ( oneError ) => {
					this.pushMessage( {
						                  type: 'error',
						                  title: 'Validation Error',
						                  text: oneError
					                  } );
				} );
				this.scrollToTop();
				return false;
			}
			
			return true;
		}
	}
};